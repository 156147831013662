import { render, staticRenderFns } from "./MojarScore.vue?vue&type=template&id=203867de&scoped=true&"
import script from "./MojarScore.vue?vue&type=script&lang=js&"
export * from "./MojarScore.vue?vue&type=script&lang=js&"
import style0 from "./MojarScore.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "203867de",
  null
  
)

export default component.exports