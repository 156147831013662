<template>
  <div class="oldData">
    <!-- <el-row type="flex" align="middle" style="margin-bottom: 11px;">
      <el-input placeholder="可输入专业名称" v-model="search" size="mini" clearable style="width:300px" type="primary">
        <template slot="append">搜索</template>
</el-input>
</el-row> -->
    <el-tabs v-model="activeName" v-loading='loading'>
      <el-tab-pane label="2024年专业数据" name="first">
        <mojar-score :tableData=tableData :collegeData="collegeData" :search=search :status="1"
          :line=Number(oldLine[1].score) />
      </el-tab-pane>
      <el-tab-pane label="2023年专业数据" name="second">
        <mojar-score :tableData=tableData1 :collegeData="collegeData" :search=search :status="2"
          :line=Number(oldLine[2].score) />
      </el-tab-pane>
      <el-tab-pane label="2022年专业数据" name="third">
        <mojar-score :tableData=tableData2 :collegeData="collegeData" :search=search :status="3"
          :line=Number(oldLine[3].score) />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MojarScore from "./MojarScore.vue";
export default {
  props: ["searchKey", 'collegeData', 'userInfo', 'oldLine'],
  components: { MojarScore },
  name: "",

  data() {
    return {
      loading: true,
      activeName: "first",
      tableData: [],
      tableData1: [],
      tableData2: [],
      filtersdata: [{ text: "王", value: "王" }],
      search: "",
      new_batch: 0,
      score: 40,
    };
  },
  watch: {

    searchKey() {
      this.search = this.searchKey;
      this.$forceUpdate();
    },
  },
  created() {
    this.new_batch = this.collegeData.new_batch
    this.getData();
  },
  methods: {
    getData() {
      this.tableData = [];
      this.tableData1 = [];
      this.tableData2 = [];
      this.loading = true;

      this.$fecth
        .post("volunteer/getOldScore", {
          name: this.collegeData.name,
          cid: this.collegeData.cid,
          new_batch: this.new_batch,
          subject: this.userInfo.subject,
        })
        .then((res) => {
          res = res.data;
          this.tableData = res.first;
          this.tableData1 = res.second;
          this.tableData2 = res.three;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang='less'>
.oldData {
  .el-tabs__header {
    margin: 0;
    border: 1px solid #ebeef5;
    border-bottom: 0;
  }

  .el-tabs__item {
    height: 30px;
    line-height: 30px;
    font-weight: 700;
    color: #555555;
  }

  .el-input-group__append,
  .el-input-group__prepend {
    background: #459df5;
    border: 1px solid #459df5;
    color: white;
  }

  .el-tabs__item.is-active {
    background: #459df5;
    color: white;
  }

  .el-tabs__item:hover {
    background: #459df5;
    color: white;
  }

  .el-input--mini .el-input__inner {
    height: 26px;
    line-height: 26px;
    border: 1px solid #459df5;
  }

  .el-tabs--bottom .el-tabs__item.is-bottom:last-child,
  .el-tabs--bottom .el-tabs__item.is-top:last-child,
  .el-tabs--top .el-tabs__item.is-bottom:last-child,
  .el-tabs--top .el-tabs__item.is-top:last-child {
    padding-right: 20px;
  }

  .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
  .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
  .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
  .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 20px;
  }

  .el-tabs__nav-wrap::after {
    height: 0;
  }
}
</style>
